import common from '@mixins/common';
import promptDevelop from '@assets/images/develop/img_001.png';
export default {
  name: 'Develop',
  mixins: [common],
  components: {},
  data() {
    return {
      headerTitle: '',
      serviceTel: '',
      origin: '',
      promptDevelop
    };
  },
  props: {},
  computed: {},
  methods: {
    goHome() {
      this.$router.replace({
        path: '/'
      });
    },
    async initData() {}
  },
  filters: {},
  watch: {},
  created() {},
  mounted() {
    this.initData();
  },
  beforeRouteEnter(to, from, next) {
    // console.log(to)
    // console.log(from)
    next();
  },
  beforeRouteUpdate(to, from, next) {
    // console.log(to)
    // console.log(from)
    next();
  }
};