var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-layout-wrapper"
  }, [_c('cyui-layout', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return undefined;
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [_c('div', {
          staticClass: "cyui-prompt"
        }, [_c('div', {
          staticClass: "cyui-prompt-header"
        }, [_c('div', {
          staticClass: "cyui-prompt-pic"
        }, [_c('img', {
          directives: [{
            name: "lazy",
            rawName: "v-lazy",
            value: _vm.promptDevelop,
            expression: "promptDevelop"
          }],
          attrs: {
            "alt": ""
          }
        })])]), _c('div', {
          staticClass: "cyui-prompt-body"
        }, [_c('div', {
          staticClass: "cyui-prompt-message"
        }, [_c('p', {
          staticClass: "cyui-prompt-message-title"
        }, [_vm._v("建设中")]), _c('p', [_vm._v("您浏览的页面正在快马加鞭的研发中，敬请期待…")])]), _c('div', {
          staticClass: "cyui-prompt-button"
        }, [_c('div', {
          staticClass: "cyui-btn cyui-btn-inline cyui-btn-ghost",
          on: {
            "click": _vm.goHome
          }
        }, [_vm._v("返回首页")])])])])];
      },
      proxy: true
    }])
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };